<template>
  <div class="result">
    <el-image class="image-logo" :src="require(`../../../assets/image/logoAI.png`)"></el-image>
    <div class="result-body">
      <el-container>
        <el-header>
          <el-row :gutter="10">
            <el-col :span="3"><div class="grid-content"></div></el-col>
            <el-col :span="7"><div class="grid-content short-color">短期计划（本科生或研究生阶段）</div></el-col>
            <el-col :span="7"><div class="grid-content medium-color">中期计划（毕业后五年）</div></el-col>
            <el-col :span="7"><div class="grid-content long-color">长期计划（毕业后十年或以上计划）</div></el-col>
          </el-row>
        </el-header>
        <el-main>
          <el-row :gutter="10" style="height: 12%;">
            <el-col :span="3"><div class="grid-main-left">时间跨度</div></el-col>
            <el-col :span="7"><div class="grid-main">{{ resultData[0] }}</div></el-col>
            <el-col :span="7"><div class="grid-main">{{ resultDataLately[0] }}</div></el-col>
            <el-col :span="7"><div class="grid-main">{{ resultDataFuture[0] }}</div></el-col>
          </el-row>
          <el-row :gutter="10" style="height: 18%;">
            <el-col :span="3"><div class="grid-main-left">本期目标</div></el-col>
            <el-col :span="7"><div class="grid-main">{{ resultData[1] }}</div></el-col>
            <el-col :span="7"><div class="grid-main">{{ resultDataLately[1] }}</div></el-col>
            <el-col :span="7"><div class="grid-main">{{ resultDataFuture[1] }}</div></el-col>
          </el-row>
          <el-row :gutter="10" style="height: 26%;">
            <el-col :span="3"><div class="grid-main-left">细分目标</div></el-col>
            <el-col :span="7"><div class="grid-main">{{ resultData[2] }}</div></el-col>
            <el-col :span="7"><div class="grid-main">{{ resultDataLately[2] }}</div></el-col>
            <el-col :span="7"><div class="grid-main">{{ resultDataFuture[2] }}</div></el-col>
          </el-row>
          <el-row :gutter="10" style="height: 18%;">
            <el-col :span="3"><div class="grid-main-left">计划内容</div></el-col>
            <el-col :span="7"><div class="grid-main">{{ resultData[3] }}</div></el-col>
            <el-col :span="7"><div class="grid-main">{{ resultDataLately[3] }}</div></el-col>
            <el-col :span="7"><div class="grid-main">{{ resultDataFuture[3] }}</div></el-col>
          </el-row>
          <el-row :gutter="10" style="height: 26%;">
            <el-col :span="3"><div class="grid-main-left">策略和措施</div></el-col>
            <el-col :span="7"><div class="grid-main">{{ resultData[4] }}</div></el-col>
            <el-col :span="7"><div class="grid-main">{{ resultDataLately[4] }}</div></el-col>
            <el-col :span="7"><div class="grid-main">{{ resultDataFuture[4] }}</div></el-col>
          </el-row>
        </el-main>
      </el-container>
    </div>
    <el-image class="logo-image" :src="require(`../../../assets/image/logomini.png`)"></el-image>
  </div>
</template>

<script>
import { getResult, delQuestions } from "../../../request/api";

export default {
  data () {
    return {
      questionnaire_result_id: undefined,
      resultData: [],
      resultDataLately: [],
      resultDataFuture: []
    }
  },
  methods: {
    async init() {
      if (this.questionnaire_result_id === undefined) return

      const loading = this.$loading({
        lock: true,
        text: '报告生成中，请稍后……',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.2)'
      });

      let postData = {
        questionnaire_result_id: this.questionnaire_result_id
      }
      const res = await delQuestions(postData)
      if (res.status !== 200 || res.data.code !== '1000') {
        this.$message.error(res.data.msg)
        return
      }

      this.resultData = this.getResultData()
      this.resultDataLately = this.getResultData()
      this.resultDataFuture = this.getResultData()
      
      loading.close()
    },
    async getResultData() {
      const res = await getResult(this.questionnaire_result_id)
      if (res.status !== 200 || res.data.code !== '1000') {
        this.init()
      }
      return JSON.parse(res.data.data)
    }
  },
  created () {
    this.questionnaire_result_id = this.$route.query.questionnaire_result_id
  },
  mounted () {
    // this.init()
  }
}
</script>

<style lang="less" scoped>
.result {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to bottom right, #6dbeff, #fff);
  position: relative;
  .image-logo {
    width: 12%;
    position: absolute;
    top: 30px;
    left: 30px;
  }
  .result-body {
    background-color: #fff;
    height: 76%;
    width: 90%;
    padding: 20px;
    box-sizing: border-box;
    z-index: 1;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    .el-container {
      height: 100%;
      .el-header {
        .grid-content {
          border-radius: 4px;
          height: 52px;
          color: #fff;
          font-weight: bold;
          line-height: 52px;
          text-align: center;
          font-size: 22px;
        }
        .short-color {
          background-color: #9fd5ff;
        }
        .medium-color {
          background-color: #5eb8ff;
        }
        .long-color {
          background-color: #1096ff;
        }
      }
      .el-main {
        padding: 0 20px;
        .el-col {
          height: 100%;
          .grid-main-left {
            background-color: #ffe284;
            width: 120px;
            height: 40px;
            border-radius: 4px;
            text-align: center;
            line-height: 40px;
            margin: auto;
          }
          .grid-main {
            border: 1px solid #9fd5ff;
            height: 90%;
            border-radius: 4px;
            padding: 4px;
            box-sizing: border-box;
            overflow-y: auto;
            font-size: 14px;
          }
        }
      }
    }
  }
  .logo-image {
    pointer-events: none;
    position: absolute;
    bottom: 0px;
    width: 700px;
    opacity: 0.1;
    right: 0px;
    z-index: 0;
  }
}
</style>